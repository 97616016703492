import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { AdminMenusService } from './admin-menus.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { ShareService } from '../services/share.service';
import { ApiserviceService } from '../../apiservice.service';

interface AdminMenus {
  key: string,
  title?: string,
  icon?: string,
  url: string,
  show: boolean
}

interface userAccess {
  GET: boolean,
  POST: boolean,
  PATCH: boolean,
  DELETE: boolean
}

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>umsapi_user_resource_post</li>
 * </ol>
 *
 */

@Component({
  selector: 'admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.css']
})
export class AdminSidebarComponent implements OnInit {
  @Input() size: any;
  selectedExpansionPanel: string;

  adminMenus: AdminMenus[] = [
    // {
    //   key: 'menu_configuration',
    //   title: "Menu Configuration",
    //   icon: 'menuConfig',
    //   url: '/admin/menu',
    //   show: false
    // },
    {
      key: 'cityDetail',
      title: "City Wise Detail",
      icon: 'field_settings',
      url: '/admin/cityDetail',
      show: false
    },
    // {
    //   key: 'csv_upload',
    //   title: "CSV Upload",
    //   icon: 'csvUpload',
    //   url: '/admin/csv_upload',
    //   show: false
    // },
    // {
    //   key: 'manage_csv',
    //   title: "Manage CSV Upload",
    //   icon: 'listbox',
    //   url: '/admin/manage_csv',
    //   show: false
    // },
    {
      key: 'valuation',
      title: "Valuation",
      icon: 'listbox',
      url: '/admin/',
      show: false
    },
    {
      key: 'categories',
      title: "Nearby Categories",
      icon: 'category',
      url: '/admin/categories',
      show: false
    },
    // {
    //   key: 'sync-data',
    //   title: "Sync Data",
    //   icon: 'synchronize',
    //   url: '/admin/sync-data',
    //   show: false
    // },
    {
      key: 'sync-service',
      title: "Sync Service",
      icon: 'synchronize',
      url: '/admin/sync-service',
      show: false
    },
    // {
    //   key: 'block-valuation',
    //   title: "Block Valuation",
    //   icon: 'block',
    //   url: '/admin/block-valuation',
    //   show: false
    // },
    // {
    //   key: 'download-price-data',
    //   title: "Download Price Data",
    //   icon: 'pricing',
    //   url: '/admin/download-price-data',
    //   show: false
    // },
    // {
    //   key: 'remove_block',
    //   title: "Remove blocks",
    //   icon: 'removeBlock',
    //   url: '/admin/remove-block',
    //   show: false
    // },
    {
      key: 'settings',
      title: "Setting",
      icon: 'settings',
      url: '/admin/settings',
      show: false
    },
    {
      key: 'broker_master',
      title: "Broker Master",
      icon: 'Broker',
      url: '/admin/broker-master',
      show: false
    },    
    {
      key: 'blogList',
      title: "Blogs",
      icon: 'blog_icon',
      url: '/admin/blog',
      show: false
    },
    {
      key: 'listable_attribute',
      title: "Listable Attribute",
      icon: 'attributeList',
      url: '/admin/listable-attribute',
      show: false
    },
    // {
    //   key: 'valuation_factor_master',
    //   title: "Valuation Factor Master",
    //   icon: 'valuationFactor',
    //   url: '/admin/valuation-factor-master',
    //   show: false
    // },
    {
      key: 'masters_ui_listbox',
      title: "Masters UI",
      icon: 'listbox',
      url: '/admin/masters-ui-listbox',
      show: false
    },
    {
      key: 'transaction_attribute',
      title: "Transaction Attribute",
      icon: 'transactionAttribute',
      url: '/admin/transaction-attribute',
      show: false
    },
    // {
    //   key: 'compare_parameter',
    //   title: "Comparison Parameter",
    //   icon: 'comparison',
    //   url: '/admin/comparison-parameter',
    //   show: false
    // },
    // {
    //   key: 'neighbourhood_markers',
    //   title: "Neighbourhood Markers",
    //   icon: 'neighMarkers',
    //   url: '/admin/neighbourhood-markers',
    //   show: true
    // },
    // {
    //   key: 'send_notification',
    //   title: "Send Notification",
    //   icon: 'sendNotif',
    //   url: '/admin/send-notification',
    //   show: true
    // },
    {
      key: 'manage_redis',
      title: "Manage Redis",
      icon: 'redis',
      url: '/admin/manage-redis',
      show: false
    },


  ];

  // csvMenus: AdminMenus[] = [{
  //   key: 'manage_csv_upload',
  //   title: "CSV Upload",
  //   icon: 'sendNotif',
  //   url: '/admin/manage-csv-upload',
  //   show: true
  // },
  // {
  //   key: 'csv_template',
  //   title: "CSV Template",
  //   icon: 'redis',
  //   url: '/admin/manage-csv-template',
  //   show: true
  // }]

  valuationMenus: AdminMenus[] = [{
    key: 'block-valuation',
    title: "Block Valuation",
    icon: 'block',
    url: '/admin/block-valuation',
    show: false
  },
  {
    key: 'download-price-data',
    title: "Download Price Data",
    icon: 'pricing',
    url: '/admin/download-price-data',
    show: false
  },
  {
    key: 'csv_upload',
    title: "CSV Upload",
    icon: 'csvUpload',
    url: '/admin/csv_upload',
    show: false
  },
  {
    key: 'remove_block',
    title: "Remove blocks",
    icon: 'removeBlock',
    url: '/admin/remove-block',
    show: false
  },
  {
    key: 'valuation_factor_master',
    title: "Valuation Factor Master",
    icon: 'valuationFactor',
    url: '/admin/valuation-factor-master',
    show: false
  },
  {
    key: 'compare_parameter',
    title: "Comparison Parameter",
    icon: 'comparison',
    url: '/admin/comparison-parameter',
    show: false
  }]

  attributes_access: userAccess;
  constructor(private menus: AdminMenusService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
    private fnc: CommonfunctionService, private share: ShareService,
    private api: ApiserviceService) {
    iconRegistry.addSvgIcon(
      'gear',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/gear.svg'))
      .addSvgIcon('csvUpload', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/newIcons/CSV_upload_normal.svg'))
      .addSvgIcon('activity_detail', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/activity_detail.svg'))
      .addSvgIcon('field_settings', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/field_settings.svg'))
      .addSvgIcon('masters', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/masters.svg'))
      .addSvgIcon('property_listing', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/property_listing.svg'))
      .addSvgIcon('settings', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/newIcons/Setting_normal.svg'))
      .addSvgIcon('property_type', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/property_type.svg'))
      .addSvgIcon('menuConfig', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/newIcons/Menu_configuration_normal.svg'))
      .addSvgIcon('cloud_upload', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/cloud_upload.svg'))
      .addSvgIcon('category', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/newIcons/Nearby_categories_normal.svg'))
      .addSvgIcon('synchronize', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/newIcons/Sync_data_normal.svg'))
      .addSvgIcon('pricing', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/newIcons/Price_data_normal.svg'))
      .addSvgIcon('block', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/newIcons/Block_valuation_normal.svg'))
      .addSvgIcon('gisDataUpload', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/my_listing.svg'))
      .addSvgIcon('removeBlock', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/newIcons/Removing_block_normal.svg'))
      .addSvgIcon('Broker', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/newIcons/Broker_normal.svg'))
      .addSvgIcon('comparison', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/newIcons/Comparison_parameter_normal.svg'))
      .addSvgIcon('dragx', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/drag-v.svg'))
      .addSvgIcon('valuationFactor', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/newIcons/Valuation_factor_normal.svg'))
      .addSvgIcon('attributeList', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/newIcons/Attribute_list_normal.svg'))
      .addSvgIcon('transactionAttribute', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/newIcons/Transaction_attributes_normal.svg'))
      .addSvgIcon('neighMarkers', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/newIcons/Neighbourhood_marker_normal.svg'))
      .addSvgIcon('sendNotif', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/newIcons/Send_notification_normal.svg'))
      .addSvgIcon('redis', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/newIcons/redis.svg'))
      .addSvgIcon('listbox', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/listbox_icon-1.svg'))
      .addSvgIcon('blog_icon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/blog_icon_w.svg'));
    this.getAllowResources();
  }

  ngOnInit() {

  }

  /**
   * getAllowResources function getting allow resources
   * @returns Void
   */
  getAllowResources() {
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body)
      .subscribe({
        next :(data: any) => {
        // if (data.status !== 403 || data.status !== 0) {
        //   this.api.logout(false);
        // }
        //  else {
          this.api.allowResources = data.data;
          this.getAllowResource();
        // }

      }, error:err=>{

      }
    })
  }

  isSmall() {
    return this.size === 'small';
  }

  isNormal() {
    return this.size === 'normal';
  }

  getActive(menu) {
    return this.menus.getIsActive(menu.key);
  }

  getAllowResource() {
    //this.attributes_access = this.fnc.checkResourceAccess('menu');
    this.adminMenus.forEach((v, k) => {
      let resource: userAccess;
      switch (v.key) {
        case 'menu_configuration':
          resource = this.fnc.checkResourceAccess('menu');
          if (resource.GET) v.show = true;
          break;
        case 'cityDetail':
          resource = this.fnc.checkResourceAccess('city_detail');
          if (resource.GET) v.show = true;
          break;
        case 'blogList':
          resource = this.fnc.checkResourceAccess('blogs');
          if (resource.GET) v.show = true;
          break;
        // case 'csv_upload':
        //   resource = this.fnc.checkResourceAccess('csv_upload');
        //   if (resource.GET) v.show = true;
        //   break;
        case 'manage_csv':
          resource = this.fnc.checkResourceAccess('manage_csv');
          if (resource.GET) v.show = true;
          break;
        case 'categories':
          resource = this.fnc.checkResourceAccess('nearby_categories');
          if (resource.GET) v.show = true;
          break;
        // case 'remove_block':
        //   resource = this.fnc.checkResourceAccess('remove_block');
        //   if (resource.GET) v.show = true;
        //   break;
        case 'settings':
          resource = this.fnc.checkResourceAccess('setting');
          if (resource.GET) v.show = true;
          break;
        case 'listable_attribute':
          resource = this.fnc.checkResourceAccess('listable_attribute');
          if (resource.GET) v.show = true;
          break;
        // case 'valuation_factor_master':
        //   resource = this.fnc.checkResourceAccess('valuation_factor');
        //   if (resource.GET) v.show = true;
        //   break;
        case 'transaction_attribute':
          resource = this.fnc.checkResourceAccess('transaction_attribute');
          if (resource.GET) v.show = true;
          break;
        // case 'download-price-data':
        //   resource = this.fnc.checkResourceAccess('download_price');
        //   if (resource.GET) v.show = true;
        //   break;
        // case 'block-valuation':
        //   resource = this.fnc.checkResourceAccess('block_valuation');
        //   console.log(resource)
        //   if (resource.GET) v.show = true;
        //   break;
        case 'sync-data':
          resource = this.fnc.checkResourceAccess('sync_data');
          if (resource.GET) v.show = true;
          break;
        case 'sync-service':
          resource = this.fnc.checkResourceAccess('sync_data');
          if (resource.GET) v.show = true;
          break;
        case 'broker_master':
          resource = this.fnc.checkResourceAccess('broker_master');
          if (resource.GET) v.show = true;
          break;
        // case 'compare_parameter':
        //   resource = this.fnc.checkResourceAccess('broker_master');
        //   if (resource.GET) v.show = true;
        //   break;
        case 'manage_redis':
          resource = this.fnc.checkResourceAccess('redis');
          if (resource.GET) v.show = true;
          break;
        case 'masters_ui_listbox':
          resource = this.fnc.checkResourceAccess('listbox_master');
          if (resource.GET) v.show = true;
          break;
        // case 'csv_template':
        //   resource = this.fnc.checkResourceAccess('csv_template');
        //   if (resource.GET) v.show = true;
        //   break;
        // case 'manage_csv_upload':
        //   resource = this.fnc.checkResourceAccess('csv_upload');
        //   if (resource.GET) v.show = true;
        //   break;
        case 'valuation':
          resource = this.fnc.checkResourceAccess('block_valuation');
          let resource1: userAccess = this.fnc.checkResourceAccess('csv_upload');
          let resource2: userAccess = this.fnc.checkResourceAccess('remove_block');
          let resource3: userAccess = this.fnc.checkResourceAccess('valuation_factor');
          let resource4: userAccess = this.fnc.checkResourceAccess('download_price');
          let resource5: userAccess = this.fnc.checkResourceAccess('broker_master');
          if (resource.GET || resource1.GET || resource2.GET || resource3.GET || resource4.GET || resource5.GET) v.show = true;
          break;
      }
    });
    this.valuationMenus.forEach((v, k) => {
      let resource: userAccess;
      switch (v.key) {
        case 'csv_upload':
          resource = this.fnc.checkResourceAccess('csv_upload');
          if (resource.GET) v.show = true;
          break;
        case 'remove_block':
          resource = this.fnc.checkResourceAccess('remove_block');
          if (resource.GET) v.show = true;
          break;
        case 'valuation_factor_master':
          resource = this.fnc.checkResourceAccess('valuation_factor');
          // console.log(resource)
          if (resource.GET) v.show = true;
          break;
        case 'download-price-data':
          resource = this.fnc.checkResourceAccess('download_price');
          if (resource.GET) v.show = true;
          break;
        case 'block-valuation':
          resource = this.fnc.checkResourceAccess('block_valuation');
          // console.log(resource)
          if (resource.GET) v.show = true;
          break;
        case 'compare_parameter':
          resource = this.fnc.checkResourceAccess('broker_master');
          if (resource.GET) v.show = true;
          break;
      }
    });
  }

  expandMenuitem() {
    console.log(this.size)
  }
}
