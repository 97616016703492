import { Component, OnInit } from '@angular/core';
import { ApiserviceService } from '../../apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { NotificationService } from '../services/notification.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { ConfirmDialogBox } from '../confirm-dialog/confirm-dialogbox';
import { Router } from '@angular/router';
import { UserAction } from '../user-action/user-activity';
// import { error } from 'console';
interface Activity {
  neigh: String | Number,
  block: String | Number,
  // actionId: UserAction.add_attribute.Action_ID,
  data: String | Number,
  actionType: String | Number
}
@Component({
  selector: 'app-remove-block',
  templateUrl: './remove-block.component.html',
  styleUrls: ['./remove-block.component.css']
})
export class RemoveBlockComponent implements OnInit {
  public user_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  public activity: Activity = {
    neigh: null,
    block: null,
    data: null,
    actionType: null
  };
  constructor(private api: ApiserviceService, private menu: AdminMenusService,
    private fnc: CommonfunctionService,
    private notify: NotificationService,
    public dialog: MatDialog,
    private route: Router
  ) {
    this.menu.setActive('remove_block');
    this.menu.setActive('valuation');
    this.getAllowResources();
  }

  displayedColumns = ['sn', 'name', 'id', 'uid', 'action'];

  blockDataList: any = [];
  valuationTypes: any = [{
    label: "Property valuation",
    value: "property_valuation"
  },
  {
    label: "Land valuation",
    value: "land_valuation"
  },
  {
    label: "Built-up area valuation",
    value: "bua_valuation"
  }];

  // {
  //   label: "Accessibility Index",
  //   value: "accessibility_index"
  // }

  neighbourhoods: any = [];
  SelectedvaluationType: string;
  loder_1: boolean;
  loder: boolean;

  blockData: any;
  selectedNeighUid: string;
  ngOnInit() {

  }
  
  /**
   * getAllowResources function getting allow resources
   * @returns Void
   */
  getAllowResources() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body).subscribe({
      next :(res: any) => {
        this.api.allowResources = res.data;
        this.user_access = this.fnc.checkResourceAccess('remove_block', false);
        this.getNeighbourhood(this.SelectedvaluationType);
      }
      
    })

  }
  // getAllowResourcesApi() {
  //   this.api.getUserRole(); // getting role assign to this user
  //   let body = "place_id=" + this.api.city_id;
  //   this.api.postUmsData('user/resource', body)
  //     .subscribe(
  //       (data: any) => {
  //         this.api.allowResources = data;
  //         this.user_access = this.fnc.checkResourceAccess('remove_block', false);
  //         this.getNeighbourhood(this.SelectedvaluationType);
  //       },
  //       err => {
  //         if (err['status'] == 403) { // log out when unauthorised
  //           localStorage.clear();
  //           this.api.user_id = null;
  //           this.route.navigateByUrl('admin/login');
  //         }
  //       }
  //     )
  // }
  selectValueType(event) {
    this.SelectedvaluationType = event.value;
    if (this.SelectedvaluationType) {
      this.neighbourhoods = [];
      // this.blockDataList = [];
      this.blockData = null;
      this.getNeighbourhood(this.SelectedvaluationType)
    }
  }
  getNeighbourhood(SelectedvaluationType) {
    if (!this.user_access.GET) {
      this.notify.notify("You are not authorized to Remove Block", "warn");
      return;
    }
    this.loder_1 = true
    this.api.getData(`get-valuation-neigh-block?type=${SelectedvaluationType}`).subscribe({
      next:(res:any) => {
      this.neighbourhoods = res.data;
      this.loder_1 = false;
      if (this.selectedNeighUid) {
        this.getBlockList(this.selectedNeighUid);
      }
    }, error:(err) => {
      this.neighbourhoods = [];
      this.loder_1 = false;
    }
    })
  }

  selectneighUID(neigh_uid) {
    // console.log(neigh_uid);
    this.selectedNeighUid = neigh_uid.value;
    this.getBlockList(this.selectedNeighUid);
  }
  
  // selectneighUID(neigh_uid) {
  //   this.selectedNeighUid = neigh_uid.value;
  //   this.getBlockList(this.selectedNeighUid);
  // }

  /**
   * This is a function to get blocks
   * @param neigh_uid neigh_uid is param
   * @returns 
   */

  getBlockList(neigh_uid) {
    if (!this.SelectedvaluationType || !neigh_uid) return;
    this.blockData = null;
    this.loder = true;
    this.activity.neigh = neigh_uid;
    this.api.getData(`get-valuation-neigh-block?type=${this.SelectedvaluationType}_block&neigh_uid=${neigh_uid}`).subscribe({
      next:(res: any) => {
   if (res.data?.length) {
        this.blockDataList = res.data;
      }else{
        this.blockDataList =[];
      }
      this.loder = false;
    }, error:(err) => {
      this.blockDataList = [];
      this.loder = false;
    }
    })
  }

  deleteBlock(v, event) {
    if (!this.user_access.DELETE) {
      this.notify.notify('You are not authorized to delete block', 'warn');
      return;
    }
    if (v) this.blockData = v;
    let _data = {
      parent_data: this,
      message: 'Do you want to delete block'
    }
    const dialogRef = this.dialog.open(ConfirmDialogBox, {
      width: '350px',
      data: _data
    });
    dialogRef.afterClosed().subscribe(result => {
    });

  }
  public confirmDialogYes(check) {
    if (check == 'YES') {
      this.loder = true
      this.activity.block = this.blockData.uid;
      // this.activity.actionType = UserAction.block_remove.Action_ID;
      this.api.deleteData(`delete-valuation-block?type=${this.SelectedvaluationType}&block_uid=${this.blockData.uid}&id=${this.blockData.id}&user_id=${this.api.user_id}`).subscribe({
        next:(res:any) => {
        // if(res['em_valuation_block_delete'] == "Block deleted for bua valuation"){
        //   this.api.logUserActivity(this.activity)
        // }
        this.loder = false
        this.notify.notify(res.message, 'success');
        this.getBlockList(this.selectedNeighUid);
      }, error:(err) => {
        this.loder = false;
        this.notify.notify(this.fnc.getErrorMessage(err), "error")
      }
      })
    }
  }
}
