<div>
    <div fxHide="true" fxShow.gt-sm="true" fxLayout="row" class="w-100 crumb-c" fxLayoutAlign="space-between center">
        <div fxLayout="row" class="w-100 bleft" fxLayoutAlign="start center">
            <label *ngIf="action_type=='add'" class="main-heading d-flex align-items-center">  
                <a matTooltip="Go Back" class="back-btn d-flex align-items-center" (click)="goBack()">
                    <mat-icon class="mat-icons-sm mr-2">arrow_backward</mat-icon>
                </a>
                <span *ngIf="selectedType=='details'">Add Details Information</span>
                <span *ngIf="selectedType=='trends'">Add Trends Information</span>
            </label>

            <label *ngIf="action_type=='edit'" class="main-heading d-flex align-items-center">
                <a matTooltip="Go Back" class="back-btn d-flex align-items-center" (click)="goBack()">
                    <mat-icon class="mat-icons-sm mr-2">arrow_backward</mat-icon>
                </a>
                <span *ngIf="selectedType=='details'">Update Details Information</span>
                <span *ngIf="selectedType=='trends'">Update trends Information</span>
            </label>
        </div>
    </div>
    <mat-progress-bar mode="indeterminate" color="primary" *ngIf="loader"></mat-progress-bar>
    <form [formGroup]="statsForm" autocomplete="off" novalidate (ngSubmit)="addStatistics(statsForm)">
        <div class="container-fluid left-50">
            <div class="content">
                <div class="user-details">
                    <div class="grid">
                        <div>
                            <div class="input-box card-section">
                                <span class="detail type">Language <span class="text-danger">*</span> </span>
                                <mat-select class="mt-2" placeholder="Select Language" (selectionChange)="selectedLang($event.value)" formControlName="formLanguage" tabindex="1">
                                    <mat-option *ngFor="let language of language_list" [value]="language.language_id">
                                        {{ language.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error>
                                    <span class="text-danger" *ngIf="(statsFormControl.formLanguage.touched || statsForm.valid) && statsFormControl.formLanguage.errors?.required">
                                    Language is required
                                </span>
                                </mat-error>
                            </div>                            
                            <div class="input-box card-section">
                                <span class="detail mb-3">Title</span>
                                <input type="text" placeholder="Enter Title Here" formControlName="title" tabindex="2">
                                <mat-error *ngIf="title.invalid && title.touched">
                                    <span class="text-danger" *ngIf="title.errors?.required">
                                        Title is required
                                    </span>
                                </mat-error>
                            </div>
                            <ng-container *ngIf="selectedType=='details'">
                                <div class="input-box card-section">
                                    <span class="detail mb-3">Slug <span *ngIf="entity_name == 'neighbourhood'" class="text-danger">*</span></span>
                                    <input type="text" placeholder="" formControlName="slug" tabindex="3">
                                    <mat-error *ngIf="Slug.invalid && Slug.touched">
                                        <span class="text-danger" *ngIf="Slug.errors?.required">
                                            Slug is required
                                        </span>
                                    </mat-error>
                                </div>
                                <div class="input-box card-section">
                                    <span class="detail mb-3">Meta Title <span class="text-danger">*</span></span>
                                    <input type="text" placeholder="Enter Meta Title Here" formControlName="meta_title" tabindex="4">
                                    <mat-error *ngIf="metaTitle.invalid && metaTitle.touched">
                                        <span class="text-danger" *ngIf="metaTitle.errors?.required">
                                            Meta Title is required
                                        </span>
                                    </mat-error>
                                </div>
                                <div class="input-box card-section">
                                    <span class="detail mb-3">Meta Description <span class="text-danger">*</span></span>
                                    <textarea  type="text" placeholder="Enter Meta Description" formControlName="meta_description" tabindex="5"></textarea>
                                    <mat-error *ngIf="metaDescription.invalid && metaDescription.touched">
                                        <span class="text-danger" *ngIf="metaDescription.errors?.required">
                                            Meta Description is required
                                        </span>
                                    </mat-error>
                                </div>
                            </ng-container>
                            <!-- <div class="input-box mt-5 mb-5">
                                <span class="detail type">Type of Information<span class="text-danger">*</span></span>
                                <mat-select class="mt-2" placeholder="Select type" formControlName="infoType">
                                    <mat-option *ngFor="let type of type_info" [value]="type.value">
                                        {{type.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>
                                    <span class="text-danger" *ngIf="(statsFormControl.infoType.touched || statsForm.valid) && statsFormControl.infoType.errors?.required">
                                    Typr of Information is required
                                </span>
                                </mat-error>
                            </div> -->
                            <div class="card-section">
                                <div class="detail" *ngIf="selectedType=='details'">Data</div>
                                <div class="detail" *ngIf="selectedType=='trends'">Analytics</div>
                                
                                <div class="grid data-div mt-3">

                                    <div class="detail">Title
                                        <!-- formarray -->
                                        <div formArrayName="keys">
                                            <div *ngFor="let key of keysSet;let kk = index">
                                                <div [formGroupName]="kk" fxLayout="row" fxLayoutAlign="space-between start">
                                                    <input matInput placeholder="Enter Key Here" type="text" formControlName="keys" [tabindex]="4+kk">
                                                </div>
                                            </div>
                                        </div>
                                        <!--  -->
                                    </div>
                                    <div class="detail">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <span>Value</span>
                                            <li matTooltip="Add Row" class="pointer" (click)="addRow()">
                                                <img src="/assets/icon/Group 1347.svg">
                                            </li>
                                        </div>
                                        <!-- formArray -->
                                        <div formArrayName="value">
                                            <div *ngFor="let value of valuesSet;let jj = index">
                                                <div [formGroupName]="jj" fxLayout="row" fxLayoutAlign="space-between center">
                                                    <input placeholder="Enter Value Here" matInput type="text" formControlName="value" [tabindex]="4+jj">

                                                    <div fxFlex="10%">
                                                        <li class="pointer" matTooltip="Remove Row" (click)="removeRow(jj)">
                                                            <img src="../../../../assets/images/svgicons/remove.svg" alt="">
                                                        </li>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--  -->
                                    </div>
                                </div>
                            </div>

                            <!-- Image Upload Section  -->
                            <div class="card-section image_card_section"  *ngIf="selectedType=='trends'">
                                <div class="detail">Images<span class="text-danger">*</span></div>
                                <!--  -->
                                <div class="data-div mt-3">

                                    <div class="">
                                        <div fxLayout="row" fxLayoutAlign="space-between center">
                                            <div class="">Title</div>
                                            <div class=" add_button text-right">
                                                <div matTooltip="Add Row" class="pointer" (click)="addCategorysRow()">
                                                    <img src="/assets/icon/Group 1347.svg">
                                                </div>
                                            </div>
                                        </div> 
                                        <!-- formarray -->
                                        <div formArrayName="categorys" class="mt-2">
                                            <div *ngFor="let category of categoryTitleSet;let i = index">
                                                <div [formGroupName]="i" class="groups">
                                                    <div class="file_title d-flex">
                                                        <input [tabindex]="30+i" matInput placeholder="Enter Title Here" type="text" formControlName="categorys" (keyup)="updateTitle($event, i)">
                                                    </div>
                                                    <div class="remove_btn">
                                                        <li class="pointer " matTooltip="Remove Row" (click)="removeCategorysRow(i)" *ngIf="showRemove">
                                                            <img src="../../../../assets/images/svgicons/remove.svg" alt=""> 
                                                        </li>
                                                    </div>
                                                    <div class="image_thumbnail">
                                                        <ul>
                                                            <li>
                                                                
                                                                <div class="file_button">
                                                                    <span class="uploadOutlined">
                                                                        <span *ngIf="imageUrls[i]?.loader" class="loader"></span>
                                                                        <span *ngIf="!imageUrls[i]?.loader" class="plus">+</span>
                                                                        <span>Drop file <br> OR <br> Upload</span>
                                                                    </span>
                                                                    <input [tabindex]="30+i" *ngIf="!imageUrls[i]?.loader" type="file" class="pointer" title=" " accept="image/*" (change)="uploadImage($event, i, imageCropeDialog)">
                                                                </div>
                                                                
                                                            </li>
                                                            <li *ngFor="let url of imageUrls[i]; let jj = index">
                                                                <img [src]="url" alt="" class="thumb">
                                                                <input type="radio" matTooltip="Set Default" class="radioButton" [checked]="images && images[i].default == jj? true:false" id="radio{{i}}{{jj}}" [name]="'radio' + i" (change)="defaultImage($event, i)"  value="{{jj}}">
                                                                <label for="radio{{i}}{{jj}}" matTooltip="Set Default" [matTooltipPosition]="'above'"></label>
                                                                <span class="overlayDefault"></span>
                                                                <span class="pointer remove_image" matTooltip="Remove Image" (click)="removeCategorysImage(i, jj)">
                                                                    <img src="../../../../assets/images/svgicons/delete_image.png" alt="">
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--  -->
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div>
                            <div class="input-box card-section">
                                <div class="detail">Description</div>
                                <!-- <textarea class="mt-3 detail-description" placeholder="Type here" formControlName="description"></textarea> -->
                                <angular-editor formControlName="description" [config]="editorConfig" tabindex="3"></angular-editor>
                            </div>

                            <div class="input-box card-section">
                                <span class="detail ">Entity<span class="text-danger">*</span></span>
                                <!-- (selectionChange)="getSelectedEntityValue($event, null, true)" -->
                                <mat-select tabindex="17" placeholder="Choose entity" (selectionChange)="getSelectedEntityValue($event, null)" class="mt-2" formControlName="entity">
                                    <mat-option *ngFor="let type of entity" [value]="type.value">{{type.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>
                                    <span class="text-danger" *ngIf="(statsFormControl.entity.touched || statsForm.valid) && statsFormControl.entity.errors?.required">
                                        Entity is required
                                    </span>
                                </mat-error>
                                <div class="input-box mt-3 d-flex justify-content-between">
                                    <div class="detail mt-3" style="width: 47%;">
                                        <span>Neighbourhood</span>
                                        <mat-select tabindex="18" class="mt-2" (selectionChange)="getBlocks($event)" [disabled]="loader" placeholder="Neighborhood" formControlName="neighborhood">
                                            <input class="searchIn" mat-input [(ngModel)]="searchText" placeholder="Type here to search..." [ngModelOptions]="{standalone: true}" />
                                            <mat-option *ngFor="let neigh of neighborhoodsList | filter : 'neigh_name': searchText" [value]="neigh.neigh_uid">
                                                {{neigh.neigh_name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="neighborhood.invalid && neighborhood.touched">
                                            <span class="text-danger" *ngIf="neighborhood.errors?.required">
                                                Neighbourhood is required
                                            </span>
                                        </mat-error>
                                    </div>
                                    <div class="detail mt-3" style="width: 47%;">
                                        <span>Block</span>
                                        <mat-select tabindex="19" class="mt-2" placeholder="Block" formControlName="block">
                                            <mat-option *ngFor="let block of blocksList" [value]="block.block_uid">
                                                {{block.block_name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="blockData.invalid && blockData.touched">
                                            <span class="text-danger" *ngIf="blockData.errors?.required">
                                                Block is required
                                            </span>
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                            <div class="card-section" *ngIf="selectedType=='trends'">
                                <div class="detail">Key Attractions</div>
                                <div class="grid data-div mt-3">
                                    <div class="detail">Title
                                        <!-- formarray -->
                                        <div formArrayName="attrKeys">
                                            <div *ngFor="let key of attrkeysSet;let kk = index">
                                                <div [formGroupName]="kk" fxLayout="row" fxLayoutAlign="space-between start">
                                                    <input matInput placeholder="Enter Key Here" type="text" formControlName="attrKeys" [tabindex]="40+kk">
                                                </div>
                                            </div>
                                        </div>
                                        <!--  -->
                                    </div>
                                    <div class="detail">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <span>Value</span>
                                            <li matTooltip="Add Row" class="pointer" (click)="addAttrRow()">
                                                <img src="/assets/icon/Group 1347.svg">
                                            </li>
                                        </div>
                                        <!-- formArray -->
                                        <div formArrayName="attrValues">
                                            <div *ngFor="let value of attrvaluesSet;let jj = index">
                                                <div [formGroupName]="jj" fxLayout="row" fxLayoutAlign="space-between center">
                                                    <input placeholder="Enter Value Here" matInput type="text" formControlName="attrValues" [tabindex]="40+jj">

                                                    <div fxFlex="10%">
                                                        <li class="pointer" matTooltip="Remove Row" (click)="removeAttrRow(jj)">
                                                            <img src="../../../../assets/images/svgicons/remove.svg" alt="">
                                                        </li>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--  -->
                                    </div>
                                </div>
                            </div>
                            <div class="button d-flex justify-content-end">
                                <button *ngIf="action_type=='add'" type="submit" [disabled]="statsForm.invalid" tabindex="60"> Submit</button>
                                <button *ngIf="action_type=='edit'" type="button" (click)="updateValuation(statsForm)" [disabled]="statsForm.invalid"> Update</button>
                                <button class="cancel-btn" (click)="goBack()" tabindex="61">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

</div>

