// import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ImageCroppedEvent , base64ToFile } from 'ngx-image-cropper';
import { ApiserviceService } from '../../../apiservice.service';
import { ImageEditorComponent } from '../../image-editor/image-editor.component';
import { NotificationService } from '../../services/notification.service';
import { DatePipe } from '@angular/common';
import * as _moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
export const YEAR_MODE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: "app-add-blog",
  templateUrl: "./add-blog.component.html",
  styleUrls: ["./add-blog.component.scss"],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: YEAR_MODE_FORMATS },
  ],
})
export class AddBlogComponent implements OnInit {
  @Output() back = new EventEmitter();
  @Input() action_type: string;
  @Input() update_data: any;
  @Input() selectedType: string;
  public postContentControl: FormControl;
  public maxLength: number = 250;
  public loader = false;
  public showRemove = false;
  public blocksList: any = [];
  public selectedBlock;
  public searchText: string = "";
  public blogForm: any = [];
  public images: Array<{}> = [];
  public imageUrls:string;
  public filenNme: string;
  public formData: any;
  public dialogReff:any;
  public dialogReference:any;
  public imgChangeEvt: any = "";
  public cropImgPreview: any = "";
  public rowIndex:any;
  public imageloader:boolean=false;

  public language_list: any = [];
  public selected_formlng: any;
  dragDisabled: boolean = true;
  length_error_message: boolean = false;
  charector:number = 0;

  get postTitle(): AbstractControl {
    return this.blogForm.get("post_title");
  }
  get postContent(): AbstractControl {
    return this.blogForm.get("post_content");
  }
  get postAuthor(): AbstractControl {
    return this.blogForm.get("post_author");
  }
  get postCategory(): AbstractControl {
    return this.blogForm.get("post_category");
  }
  get blogUrl(): AbstractControl {
    return this.blogForm.get("post_url");
  }
  get postDate(): AbstractControl {
    return this.blogForm.get("post_date");
  }
  minDate = new Date(2010, 0, 1);
  maxDate = new Date();

  constructor(
    private api: ApiserviceService,
    private datePipe: DatePipe,
    private notify: NotificationService,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
  ) {}
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '3rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    enableToolbar: false,
    showToolbar: true,
    defaultParagraphSeparator: 'p',
    toolbarHiddenButtons: [
      ['undo', 'redo', 'strikeThrough', 'subscrit', 'fontName'],
      [
        'fontSize',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ],
  }
  ngOnInit(): void {
    // if (this.action_type == "add") {
    //   this.getLanguages();
    //   this.initForm();
    // }

    this.getLanguages();
  this.initForm();

    if (this.action_type == "edit") {
      this.getLanguages();
      this.initForm();
      this.patchFormData(this.update_data);
    }

    this.blogForm.get('post_category')?.valueChanges.subscribe(value => {
      this.updateValidation(value);
    });
  }

  ngOnChanges(){
    // console.log("type in add blog ",this.selectedType);
    // if (this.blogForm) { // Ensure the form is initialized
    //   this.updateCategoryValidators();
    // }
    
  }

  updateCounter(value: any) {
    this.length_error_message = false;
    const length = value ? value.length : 0;
    this.charector =  +`${length}`;
    if (length > this.maxLength) {
      this.length_error_message = true;
    } 
  }
  getLanguages() {
    this.api.getUmsData("language").subscribe((res: any) => {
      if (res && res.data) {
        res.data.forEach((element) => {
          if (element.status == 1) {
            this.language_list.push(element);
          }
        });
      }
    });
  }

  selectedLang(lng_id) {
    if (lng_id) {
      this.selected_formlng = lng_id;
      if (this.action_type == "edit") {
        this.patchFormLangDep(this.update_data);
      }
    }
  }

  get statsFormControl() {
    return this.blogForm.controls;
  }
  initForm() {
    this.blogForm = this.fb.group({
      formLanguage: [this.api.language, Validators.required],
      post_author: [""],
      post_date: [new Date(), Validators.required],
      post_url: ["", Validators.required],
      post_category: ["", Validators.required],
      // post_category: ["", []], 
      post_title: ["", Validators.required],
      post_content: ["", [Validators.required]],
      post_image: ["", Validators.required],
    });
    // console.log('Blog Form Initialized:', this.blogForm);
    // this.blogForm.patchValue({
    //   post_date : new Date()
    // })
    // this.updateCategoryValidators();
  }

  private updateValidation(selectedCategory: string) {
    const shortDescriptionControl = this.blogForm.get('post_content');
    const urlControl = this.blogForm.get('post_url');
    const imagesControl = this.blogForm.get('post_image');

    if (selectedCategory === 'heading') {
      shortDescriptionControl?.clearValidators();
      urlControl?.clearValidators();
      imagesControl?.clearValidators();
    } else {
      shortDescriptionControl?.setValidators(Validators.required);
      urlControl?.setValidators(Validators.required);
      imagesControl?.setValidators(Validators.required);
    }

    // Update the validity of the controls
    shortDescriptionControl?.updateValueAndValidity();
    urlControl?.updateValueAndValidity();
    imagesControl?.updateValueAndValidity();
  }

  // get postCategory() {
  //   return this.blogForm.get('post_category');
  // }


  // updateCategoryValidators() {
  //   if (!(this.blogForm instanceof FormGroup)) return; // Ensure it's a FormGroup
  
  //   if (this.selectedType === 'blog') {
  //     this.blogForm.get('post_category').setValidators(Validators.required);
  //   } else {
  //     this.blogForm.get('post_category').clearValidators();
  //   }
  //   this.blogForm.get('post_category').updateValueAndValidity();
  // }
  

  patchFormData(data) {
    setTimeout(() => {
      this.imageUrls = data.post_image;
      let post_desc = data.post_content && data.post_content[this.api.language]
      ? data.post_content[this.api.language]
      : "";
      this.blogForm.patchValue({
        formLanguage: this.api.language,
        post_author: data.post_author,
        post_url: data.post_url,
        post_date: data.post_date,
        post_image: data.post_image,
        post_category : data.post_category ? String(data.post_category) : '',
        post_title:
          data.post_title && data.post_title[this.api.language]
            ? data.post_title[this.api.language]
            : "",
          post_content: post_desc
      });
      this.updateCounter(post_desc);
    }, 100);
  }

  patchFormLangDep(data) {
      setTimeout(() => {
        this.imageUrls = data.post_image;
        this.blogForm.patchValue({
          formLanguage: this.selected_formlng,
          post_author: data.post_author,
          post_url: data.post_url,
          post_date: data.post_date,
          // post_image: data.post_image,
          post_category : data.post_category ? String(data.post_category) : '',
          post_title:
            data.post_title && data.post_title[this.selected_formlng]
              ? data.post_title[this.selected_formlng]
              : "",
            post_content:
              data.post_content && data.post_content[this.selected_formlng]
              ? data.post_content[this.selected_formlng]
              : "",
        });
      }, 100);
  }


  addBlog(val:any) {
    if (this.blogForm.valid) {
      if (this.selected_formlng == undefined) {
        this.selected_formlng = this.api.language;
      }
      let data = val.value;
      let post_content = {},
          post_title = {},
          post_category = {};
          
          post_title[this.selected_formlng] = data.post_title;
          post_content[this.selected_formlng] = data.post_content;
          post_category[this.selected_formlng] = data.post_category;

      let url = `blogs`;
      let body = {
        post_title: post_title,
        post_content: post_content,
        post_author: data.post_author,
        post_date: this.datePipe.transform(data.post_date, 'yyyy-MM-dd'),
        post_url: data.post_url,
        post_category: post_category,
        status: 1,
        post_image: data.post_image,
        added_by: +this.api.user_id,
        post_type : this.selectedType,
      };
      this.loader = true;
      this.api.postData(url, body).subscribe({
        next :(res: any) => {
          this.loader = false;
          if (res && res.status == 201) {
            this.notify.notify(res.message, "success", 3000);
            this.back.emit("update-list");
          } else {
            this.notify.notify(res.message, "warn", 3000);
          }
        },
        error :(err) => {
          this.loader = false;
          this.notify.notify(err.error.message, "error", 3000);
        }
      });
    } else {
      this.notify.notify("Fill All Required Fields", "warn", 3000);
      return;
    }
  }

  updateValuation(val) {
    if (this.blogForm.valid) {
      let data = val.value;
      if (this.selected_formlng == undefined) {
        this.selected_formlng = data.formLanguage;
      }
      if (!this.update_data) {
      this.update_data = {};  // Initialize update_data if null or undefined
    }
        let url = `blogs`;
        this.update_data.post_title[this.selected_formlng] = data.post_title;
        this.update_data.post_content[this.selected_formlng] = data.post_content;
        // this.update_data.post_category[this.selected_formlng] = data.post_category;

        // console.log(data);
        this.update_data.post_category={};
        if (data?.post_category) {
          
          this.update_data.post_category[this.selected_formlng] = data.post_category; 
          // console.log("dataaaa",data.post_category)
        }else{
          this.update_data.post_category[this.selected_formlng] = "";
        }

        this.update_data.post_date = this.datePipe.transform(data.post_date, 'yyyy-MM-dd');
        this.update_data.post_author = data.post_author;
        this.update_data.post_url = data.post_url;
        this.update_data.post_image = data.post_image;
        this.update_data['updated_by'] = +this.api.user_id;
      this.loader = true;
      this.api.patchData(url, this.update_data).subscribe({
       next: (res: any) => {
          this.loader = false;
          if (res && res.status == 201) {
            this.notify.notify(res.message, "success", 3000);
            this.back.emit("update-list");
          } else {
            this.notify.notify(res.message, "warn", 3000);
          }
        },
        error :(err) => {
          this.loader = false;
          this.notify.notify(err.error.message, "error");
        }
      });
    } else {
      this.notify.notify("Fill All Required Fields", "warn", 3000);
      return;
    }
  }
  // upload image and return url
  uploadImg() {
      this.imageloader = true;
      this.api
        .postImageFile(
          `uploads/images?folder_name=blogImages`,
          this.formData
        )
        .subscribe({
          next :(res: any) => {
            if (res.url) {
              this.imageUrls = res.url;
              this.blogForm.patchValue({
                post_image : res.url
              })
              this.imageloader = false;
            }
          },
          error:  (err) => {
            this.imageUrls = "";
            this.imageloader = false;
            this.notify.notify("An error occurred", "warn", 1000);
          },
        })
  }
  /**
   *uploadImage function to upload image
   *@param event
   *@param rowindex
   */
  uploadImage(event) {
    this.imgChangeEvt = event;
    // Open image cropper dialog
    this.dialogReference = this.dialog.open(ImageEditorComponent, {
      width: '840px',
      panelClass: 'create-master-panel',
      maxHeight: 'calc(100vh - 50px)',
      height : 'auto',
      data: {
          info: event,
          type: event.type,
          size: 500,
          ratio: 6 / 4
        }
    });
    this.dialogReference.afterClosed().subscribe(result => {
      if(result){
      this.formData = result;
      this.uploadImg();
      }
    });    
  }
  
  /**
   *removeCategorysRow function to remove  image row
   *@param rowIndex
   *@param index image index
   */
  removeBlogImage() {
    this.imageUrls=null;
  }

  goBack() {
    this.back.emit('back');
  }
}
