import { Component } from '@angular/core';
import { ApiserviceService } from '../../apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { NotificationService } from '../services/notification.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-sync-table',
  templateUrl: './sync-table.component.html',
  styleUrls: ['./sync-table.component.scss']
})
export class SyncTableComponent {

  sync_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false,
  };
  selected_city: any;
  StatusJson = {
    "1": "In Progress",
    "-1": "Invalid",
    "2": "Completed",
    "-2": "Process Stopped"
  }
  StatusJsonArb = {
    "1": "في تقدم",
    "-1": "غير صالح",
    "2": "مكتمل",
    "-2": "توقفت العملية"
  }
  public searchType = '';
  public dataSource: any = [];
  public total: number;
  public today = new Date();
  public displayedColumns = ['sno',
    'id',
    'label',
    'start_date',
    'end_date',
    'time_taken',
    'existing_count',
    'success_count',
    // 'updated_by',
    'Status',
    'Remarks']
  public langcode: any = 1;
  public syncMasters: any;
  public limit: number = 10;
  public offset: number = 0;
  public pageSizeOptions: number[] = [5, 10, 25, 50, 100, 200];
  public selectedLangCode: any;
  public selectedType: any;
  public data_loader: boolean = false;
  public refresh_loader: boolean = false;

  constructor(private api: ApiserviceService, private menu: AdminMenusService,
    private fnc: CommonfunctionService,
    private notify: NotificationService) {
    this.menu.setActive('sync-service');
    this.langcode = this.api.language;
    this.getAllowResources();
    this.getCityList();
  }

  getCityList() {
    this.api.getUmsData('place')
      .subscribe({next: (data: any) => {
        if (data.status == 200 && data.data.length) {
          let cities: any[] = data.data;
          this.selected_city = cities.find(e => e.place_id == localStorage.getItem('city_id'))
        } 
      
      }
    });
  }

  getAllowResources() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body)
      .subscribe({
        next: (res: any) => {
          this.api.allowResources = res.data;
          this.sync_access = this.fnc.checkResourceAccess('sync_data', false);
          this.getSyncMasters();
          this.syncMasterDeatils();
        }
        
      })
  }

  getSyncMasters() {
    if (!this.sync_access.GET) {
      this.notify.notify('You are not authorized to Access Sync Service', 'warn');
      return
    }
    this.data_loader = true;
    this.syncMasters = [];
    let url = 'sync-master';


    this.api.getData(url).subscribe({
      next: (res: any) => {
        this.data_loader = false;
        if (res && res.data) {
          this.syncMasters = res.data;
        }
      }, error: (err) => {
        this.data_loader = false;
        this.syncMasters = [];
        this.notify.notify(err.error.message, 'error');
      }
    });
  }

  syncMasterDeatils(process?) {
    if (!this.sync_access.GET) {
      this.notify.notify('You are not Authorized to GET Sync Masters', 'warn');
      return;
    }
    this.refresh_loader = true;
    this.searchType = '';
    this.data_loader = true;
    this.selectedType = (process == 'all') ?  {name: 'all'}  : process;
    // this.dataSource = [];
    let url = `sync-process-details?limit=${this.limit}&offset=${this.offset}&sort_order=id&sort_asc=false&is_count=true`;
    if (this.selectedType.process! == 'all') {
      url += `&process_id=${this.selectedType.process_id}`;
    };
    this.api.getData(url).subscribe({
      next: (res: any) => {
        this.data_loader = false;
        setTimeout(() => { this.refresh_loader = false; }, 2000);
        if (res && res.status == 200) {
          this.total = res.totalRecord;
          this.dataSource = res.data;
        }
        else {
          this.dataSource = [];
        }
      }, error: (err) => {
        this.data_loader = false;
        setTimeout(() => { this.refresh_loader = false; }, 2000);
        this.dataSource = [];
      }
    })
  }

  manualSync() {
    if (!this.sync_access.POST) {
      this.notify.notify("You are not authorized to Refresh Sync Data", 'warn');
      return;
    }
    if (!this.selectedType) {
      this.notify.notify("Select an Entity TYPE for which sync is to processed!", 'warn');
      return;
    }
    this.data_loader = true;
    let body = {
      "action": this.selectedType.name
    };
    if (this.selectedType.name == 'address_search' && this.selected_city?.slug) {
      body['slug'] = this.selected_city.slug;
    }
    this.api.postData("sync/manual-sync", body).subscribe({
      next: (res: any) => {
        this.data_loader = false;
        if (res?.status != 500) {
          this.notify.notify("Manual Sync complete", "success");
          this.syncMasterDeatils(this.selectedType);
        } else {
          this.notify.notify(res?.message, "warn");
        }
      },
      error: () => {
        this.data_loader = false;
        this.notify.notify(
          "Could not refresh snapshot, please try again in a while",
          "error"
        );
      },
    });
  }

  returnVal(element, cols) {
    switch (cols) {
      case 'id': return element.id ? element.id : '';
      case 'label': return element.name ? this.getLabel(element.name) : '';
      case 'year': return element.year ? element.year : '';
      case 'start_date': return element.start_date ? this.fnc.formatDateUTC(element.start_date, true) : '';
      case 'end_date': return element.end_date ? this.fnc.formatDateUTC(element.end_date, true) : '';
      case 'time_taken': return (element.end_date && element.start_date) ? this.calcTimeDiff(element.end_date, element.start_date) : '';
      case 'existing_count': return element.existing_count ? element.existing_count : 0;
      case 'success_count': return element.properties_after_success ? element.properties_after_success : 0;
      case 'updated_by': return element.updated_by ? element.updated_by : '';
      case 'Remarks': return element.remarks ? element.remarks : '';
      case 'Status': return element.status ? this.getStatus(element.status, true) : '';
    }
  }

  calcTimeDiff(end_date: Date, start_date: Date) {
    if (end_date && start_date) {
      const end: any = new Date(end_date);
      const start: any = new Date(start_date);
      const res = Math.abs(end - start) / 1000;
      // get hours        
      const hours = Math.floor(res / 3600) % 24;
      // get minutes
      const minutes = Math.floor(res / 60) % 60;
      // get seconds
      const seconds = res % 60;
      const floatSeconds = seconds.toString()
      return hours + 'h' + ' ' + minutes + 'm' + ' ' + parseFloat(floatSeconds).toFixed(4) + 's';
    }
  }

  handlePagination(e: PageEvent) {
    this.limit = e.pageSize;
    if (e.previousPageIndex !== e.pageIndex) {
      this.offset = (e.pageSize * e.pageIndex);
    }
    this.syncMasterDeatils(this.selectedType);
  }

  getStatus(status, lang_dependent?) {
    if (status) {
      if (this.selectedLangCode == 'ar' && lang_dependent == true) {
        return this.StatusJsonArb[status]
      }
      else {
        return this.StatusJson[status];
      }
    } else {
      return status;
    }
  }

  getLabel(label) {
    let alias = this.syncMasters.filter((r) => r.name == label)[0];
    if (alias)
      return alias.label[this.api.language];
    else
      return;
  }

}
